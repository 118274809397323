import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hemlet from 'react-helmet'
import Video from '../components/Video'

import Layout from '../components/Layout'
// import DisplayImage from './../assets/images/LinkedIn.jpg'
import './../assets/css/style.css'

class SiteIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      <Layout>
        <Hemlet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription}/>
        </Hemlet>
        <section
          style={{
            // width: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            marginTop:'1rem',
            // textAlign:'center'

          }}
          className ="contentPadding"
          >
        {/* <img src={DisplayImage} alt={siteTitle}  align="right" style={{marginLeft:'20px'}}/> */}
          
        </section>

        <section>
          {/* <Video
              // videoSrcURL="https://youtu.be/OH0cKOMNKFk"
              //videoSrcURL="https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
              videoSrcURL="https://www.youtube.com/embed/mGv0ze0lHKA"
              videoTitle ="Intro"
          /> */}

          <p style={{textAlign:"center"}}>
            {/* <iframe width="700" height="480" src="https://www.youtube.com/embed/mGv0ze0lHKA?autoplay=1" 
            frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen></iframe> */}
            {/* <iframe width="700" height="480" src="https://www.youtube.com/embed/ZoAPhmOrPjY?autoplay=1" 
            frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/yqtbjIsHHGs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            <iframe className="video" height="480" src="https://www.youtube.com/embed/yqtbjIsHHGs?autoplay=1" 
            frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </p>


        </section>


        {/* <section>
          <div className="row" style={{
            // width: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            // textAlign:'center',
            marginTop:'1rem',
          }}
          >
            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Software</h3>
                <p>
                  C++, Python, Java, Bash, Docker,
                  OpenCV, Tensorflow, Keras, Bazel, CUDA, OpenGL, Qt
                </p>
              </div> 
            </div>

            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Full Stack</h3>
                <p>
                React, Flask, Node, RESTful, MySQL, Redis,
                Heroku, NGINX, AWS
                </p>
              </div> 
            </div>

            <div className="col-xs">
            <div className="box-row">
                <h3 className="skills">Hardware</h3>
                <p>
                  FPGA Design, System Verilog, VHDL, SoC RTL Design + Validation
                </p>
              </div> 
            </div>
            
            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Medical Device</h3>
                <p>
                  HIPAA, FDA, QMS, ISO 13485, AWS HIPAA/GDPR compliance
                </p>
              </div> 
            </div>

          </div>
        </section> */}


        {/* <section>
          <div className="row" style={{
            // width: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            // textAlign:'center',
            marginTop:'1rem',
          }}
          >
            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Software</h3>
                <p>
                  C++, Python, Java, Bash, Docker,
                  OpenCV, Tensorflow, Keras, Bazel, CUDA, OpenGL, Qt
                </p>
              </div> 
            </div>

            <div className="col-xs">
             <div className="box-row skills">
                <h3>Web Stack</h3>
                <p>
                Javascript, React.js, Node.js, Flask, MySQL, RDBMS,
                Heroku, NGINX
                </p>
              </div> 
            </div>

            <div className="col-xs">
             <div className="box-row skills">
                <h3>Hardware</h3>
                <p>
                  FPGA Design, System Verilog, VHDL, SoC RTL Design + Validation, AWS server provisioning
                </p>
              </div> 
            </div>
            
            <div className="col-xs">
              <div className="box-row skills">
                <h3>Medical Device</h3>
                <p>
                  HIPAA, FDA, QMS, ISO 13485, AWS Security compliance
                </p>
              </div> 
            </div>

          </div>
        </section> */}

        {/* <img src={DisplayImage} alt={siteTitle} /> */}

        {/* <section
          style={{
            // width: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            marginTop:'1rem',
            // textAlign:'center'

          }}
          className ="contentPadding"
          >
        <img src={DisplayImage} alt={siteTitle}  align="right" style={{marginLeft:'20px'}}/>
        <p>           
          Hello- I'm Mo. I love owning impactful products. 
        </p>
        <p>
          From the software side, I have managed
          web apps and cloud infrastructure, 
          realtime computer vision applications,
          and data pipelining processes for AI development. 

          I've curated, cleaned, and built datasets for CNN 
          training, and then have subsequently built software deploying those neural nets, both for the cloud and for realtime inferencing.
        </p>

          <p>          
          On the customer-end, I have managed OEM and distributor partnerships, co-development projects, 
          and engineering teams. 
          </p>
          <p>            
          I was most recently part of a biotech <a href="https://www.docbot.co" target="_blank">startup</a> using AI to enhance Gastroenterology. 
          </p>
        </section> */}

      </Layout>
    )
  }
}

export default SiteIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
